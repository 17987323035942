import React from 'react';
import {createMuiTheme, ThemeProvider, withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import DynamicIcon from './DynamicIcons';
import Button from '@material-ui/core/Button';
import {confirmAlert} from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import axios from "axios"; // Import css

const theme = createMuiTheme({
    typography: {
        subtitle1: {
            fontSize: 12,
            paddingLeft: 25,
        },
        body1: {
            fontWeight: 500,
        },
        buttonIcon: {
            paddingRight: 5
        },
    }
});

const styles = {
    progressBar: {
        height: "20px !Important",
        width: "20px !Important"
    },
    status_point_off: {
        display: "inline-block",
        height: 10,
        width: 10,
        borderRadius: "50%",
        backgroundColor: "red",
        marginRight: 15
    },
    status_point_on: {
        display: "inline-block",
        height: 10,
        width: 10,
        borderRadius: "50%",
        backgroundColor: "lime",
        marginRight: 15
    }
};

class ComputerList extends React.Component {
    abortController = new AbortController();

    constructor(props) {
        super(props);
        this.state = {
            progressbar: {
                color: 'primary'
            }
        };
    }

    componentWillUnmount = () => {
        this.abortController.abort();
    };

    confirmModal = (commandUuid, selectedStateUuid, name, confirm) => {
        if (confirm === null) {
            this.handleClick(commandUuid, selectedStateUuid)
            return;
        }

        confirmAlert({
            title: name + " " + confirm.title,
            message: confirm.message,
            buttons: [
                {
                    label: 'Igen',
                    onClick: () => {
                        this.handleClick(commandUuid, selectedStateUuid)
                    }
                },
                {
                    label: 'Nem',
                    onClick: () => {
                    }
                }
            ]
        });
    };

    handleClick(commandUuid, selectedStateUuid) {
        const param = commandUuid + '/' + selectedStateUuid;
        fetch('http://esp.nf1.wdsite.hu/api/bakod/addCommand/' + param,
            {signal: this.abortController.signal})
            .catch(error => {
            });
    }

    render() {
        return (
            <div>
                <ThemeProvider theme={theme}>
                    {this.props.list.length <= 0 &&
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        style={{height: '100px'}}>
                        <CircularProgress/>
                    </Grid>
                    }

                    <div className={'box-container__content'}>
                        {this.props.list.map((item) =>
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="center"
                                spacing={3}
                                key={item.uuid}>
                                <Grid item xs={12}>
                                    <div className={ item.status === "HIGH" ? this.props.classes.status_point_on : this.props.classes.status_point_off}></div>
                                    <Typography variant="h5" component="h5" display="inline">{item.name} </Typography>
                                </Grid>

                                <Grid item xs={7} sm={4}>
                                    <div className={"btn-container"}>
                                        {item.commands.map(command =>
                                            <div key={command.uuid}>
                                                <Button
                                                    disabled={(item.status === "HIGH" && command.state.name === "Be") || (item.status === "LOW" && command.state.name === "Ki")}
                                                    variant="contained"
                                                    color={command.state.color}
                                                    onClick={() => this.confirmModal(command.uuid, command.state.uuid, item.name, command.state.confirm)}
                                                    key={command.state.uuid}
                                                    size="small">
                                                    <DynamicIcon icon={command.state.icon}/> {command.state.name}
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                </Grid>

                            </Grid>
                        )}
                    </div>
                </ThemeProvider>
                {this.props.active_command !== null &&
                <Box ml={3}>
                    <Grid container direction={"row"}>
                        <Grid>
                            <CircularProgress
                                color={this.state.progressbar.color}
                                className={this.props.classes.progressBar}
                            />
                        </Grid>

                        <Grid>
                            <Typography variant="subtitle1">{this.props.active_command.progress_title}</Typography>
                        </Grid>
                    </Grid>
                </Box>
                }
            </div>
        )
    }
}

export default withStyles(styles)(ComputerList);