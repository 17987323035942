import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import Moment from 'react-moment';
import 'moment/locale/hu';
import WifiIcon from '@material-ui/icons/Wifi';
import WifiOffIcon from '@material-ui/icons/WifiOff';
import Box from '@material-ui/core/Box';

const styles = {
    root: {
        marginBottom: 10,
    },

    title: {
        marginBottom: 15,
    },
    subtitle1: {
        fontSize: 12
    },
    fromNow: {
        fontSize: 12,
        textAlign: 'right'
    },
    online: {
        fontSize: 16,
        textAlign: 'right',
        color: 'green'
    },
    offline: {
        fontSize: 16,
        textAlign: 'right',
        color: 'red'
    },
    separator: {
        marginBottom: 3,
        marginTop: 3,
        borderTop: '1px solid lightgray'
    }
};

class Statuses extends React.Component {

    render() {
        return (
            <Card className={this.props.classes.root}>
                {this.props.status_list !== null &&
                    <CardContent>
                        <Typography className={this.props.classes.title} variant="h5" component="h2">
                            <Grid container alignItems={"center"}>
                                <Grid item xs={7}>
                                    Eszközök
                                </Grid>

                                <Grid item xs={5}>

                                </Grid>
                            </Grid>
                        </Typography>
                        {this.props.status_list.map((item, index) =>
                            <div key={index}>
                                <Grid container>
                                    <Grid item xs={10}>
                                        <Typography variant="body2" component="p">
                                            {item.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Box
                                            display="flex"
                                            justifyContent="flex-end"
                                        >
                                            {item.isOnline ?
                                                <WifiIcon className={this.props.classes.online}/>
                                                :
                                                <WifiOffIcon className={this.props.classes.offline}/>
                                            }
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={4} className={this.props.classes.subtitle1}>
                                        Utoljára aktív:
                                    </Grid>
                                    <Grid item xs={8} className={this.props.classes.fromNow}>

                                        <Moment toNow date={item.updated_at}/>
                                    </Grid>
                                    <Grid item xs={12} className={this.props.classes.separator}>
                                    </Grid>
                                </Grid>
                            </div>
                        )}
                    </CardContent>
                }
            </Card>
        )
    }
}

export default withStyles(styles)(Statuses);