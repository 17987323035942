import React from 'react';
import PropTypes from 'prop-types';
import ComputerList from "./ComputerList";
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
// import PrinterTimer from "./PrinterTimer";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0}>
                    <Typography component="div">{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 0
    },
    card: {
        padding: 0,
        paddingBottom: "0!important"
    }
}));

export default function SimpleTabs(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getComputerList = (device_uuid) => {
        let result = [];
        if (props.request["getGroups"] !== undefined) {
            result = props.request["getGroups"].filter((item) => item.device_uuid === device_uuid);
        }

        return result;
    };

    const getActiveCommand = (device_uuid) => {
        let result = null;
        if (props.request["getActiveCommands"] !== undefined) {
            result = props.request["getActiveCommands"].find((item) => item.device_uuid === device_uuid);

            if (result === undefined || result.length <= 0) {
                result = null;
            }
        }

        return result;
    };

    // const getPrinterTimerDays = (device_uuid) => {
    //     let result = null;
    //
    //     if (props.request["getPrinterTimerDays"] !== undefined) {
    //         props.request["getPrinterTimerDays"] =
    //             Object.keys(props.request["getPrinterTimerDays"]).map((key) => props.request["getPrinterTimerDays"][key]);
    //         result = props.request["getPrinterTimerDays"].find((item) => item.device_uuid === device_uuid);
    //
    //         if (result === undefined || result.length <= 0) {
    //             result = null;
    //         }
    //     }
    //
    //     return result;
    // };

    return (
        <Card className={classes.card}>
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    onChange={handleChange} aria-label="simple tabs example"
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <Tab {...a11yProps(1)} label={"Kerekegyháza"}/>
                    <Tab {...a11yProps(0)} label={"Bakodpuszta"}/>
                </Tabs>
            </AppBar>
            <CardContent className={classes.card}>
                <div className={classes.root}>
                    <TabPanel value={value} index={0}>
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <ComputerList
                                    list={getComputerList('kerek-printer-01')}
                                    active_command={getActiveCommand('kerek-printer-01')}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {/*<PrinterTimer printer_timer_days={getPrinterTimerDays('kerek-printer-01')}/>*/}
                            </Grid>
                        </Grid>
                    </TabPanel>

                    <TabPanel value={value} index={1}>
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <ComputerList
                                    list={getComputerList('bakod-printer-01')}
                                    active_command={getActiveCommand('bakod-printer-01')}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {/*<PrinterTimer printer_timer_days={getPrinterTimerDays('bakod-printer-01')}/>*/}
                            </Grid>
                        </Grid>
                    </TabPanel>
                </div>
            </CardContent>
        </Card>
    );
}
