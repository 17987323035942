import React from 'react';
import './App.css';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import Statuses from './components/Statuses';
import Tabs from "./components/Tabs";
import Histories from "./components/Histories";



class App extends React.Component {
    abortController = new AbortController();
    state = {
        interval: {
            isSleep: true,
            sleepTime: 60000,
            refreshTime: 1000
        },
        request: [],
        statusInterval: null
    };

    componentDidMount = () => {
        this.wakeUp();

        window.addEventListener('mousedown', () => {
            this.wakeUp()
        }, false);
        window.addEventListener('mousemove', () => {
            this.wakeUp()
        }, false);
        window.addEventListener('touchmove', () => {
            this.wakeUp()
        }, false);
    };

    componentWillUnmount = () => {
        this.abortController.abort();
        clearInterval(this.state.statusInterval);
        clearInterval(this.requestIntervalId);
        clearTimeout(this.sleepTimerId);
    };

    getRequest() {
        fetch('http://esp.nf1.wdsite.hu/api/bakod/getRequest/',
            {signal: this.abortController.signal})
            .then(response => response.json())
            .then(data => {
                this.setState({
                    request: data
                });
            })
            .catch(error => {});
    };

    setSleep() {
        clearTimeout(this.sleepTimerId);

        this.sleepTimerId = setTimeout(() => {
            this.setState((state) => ({
                ...state,
                interval: {
                    ...state.interval,
                    isSleep: true
                }
            }));

        }, this.state.interval.sleepTime);
    };

    wakeUp() {
        if (this.state.interval.isSleep === false) {
            return;
        }

        this.setState((state) => ({
            ...state,
            interval: {
                ...state.interval,
                isSleep: false
            }
        }));

        this.requestIntervalId = setInterval(() => {
            if (this.state.interval.isSleep) {
                clearInterval(this.requestIntervalId);
                return;
            }
            this.getRequest();
        }, this.state.interval.refreshTime);

        this.setSleep();
    }

    getStatuses = () => {
        let result = null;

        if (this.state.request["getDeviceLoggedin"] !== undefined) {
            result =
                Object.keys(this.state.request["getDeviceLoggedin"]).map((key) =>
                    this.state.request["getDeviceLoggedin"][key]);

            if (result === undefined || result.length <= 0) {
                result = null;
            }
        }

        return result;
    };

    getHistories = () => {
        return (this.state.request["getDeviceLoggedin"] !== undefined)
            ? this.state.request["getHistories"]
            : null;
    };

    render () {
        return (
            <div className="App">
                <Container maxWidth={"lg"}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={8}>
                            <Tabs request={this.state.request} />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Statuses status_list={this.getStatuses()} />
                            <Histories history_list={this.getHistories()} />
                        </Grid>
                    </Grid>
                </Container>
            </div>
        );
    }
}

export default App;
