import React from 'react';
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import Moment from 'react-moment';
import 'moment/locale/hu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import Box from '@material-ui/core/Box';

const styles = {
    root: {
        marginBottom: 10
    },
    fromNow: {
        fontSize: 12,
        textAlign: 'right'
    },
    row: {
        fontSize: 15,
        marginBottom: 5,
        lineHeight: 1,
        paddingTop: 5,
        paddingBottom: 5,

        '&:hover': {
            backgroundColor: '#e6e6e6',
        },
    },
    hourIcon: {
        fontSize: 15,
        lineHeight: 1,
        marginRight: 5,
        color: 'gray'
    },
    hourLabel: {
        color: 'gray'
    },
    moreBtn: {
        padding: 3
    }
};

class Histories extends React.Component {
    state = {
        histories: [],
        more: false
    };

    more() {
        this.setState({
            more: !this.state.more,
        })
    }

    render() {
        return (
            <Card className={this.props.classes.root}>
                {this.props.history_list !== null &&
                <CardContent>
                    <Typography className={this.props.classes.title} variant="h5" component="h2">
                        <Grid container alignItems={"center"}>
                            <Grid item xs={7}>
                                Események
                            </Grid>

                            <Grid item xs={5}>
                                <Box display={"flex"} justifyContent={"flex-end"}>
                                    <IconButton
                                        aria-label="delete" className={this.props.classes.moreBtn} color="primary"
                                        onClick={() => this.more()}
                                    >
                                        <MoreVertIcon fontSize="small"/>
                                    </IconButton>
                                </Box>
                            </Grid>
                        </Grid>
                    </Typography>
                    <div className={this.props.classes.root}>
                        {this.props.history_list.filter((item, index) => index < (this.state.more ? 15 : 5)).map((item, index) =>
                            <Grid container key={index} className={this.props.classes.row}>
                                <Grid item xs={8}>
                                    {item.message}
                                </Grid>
                                <Grid item xs={4}>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="flex-end">
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="flex-start">

                                            <QueryBuilderIcon className={this.props.classes.hourIcon}/>
                                            <Moment format="MM/DD H:mm"
                                                    className={this.props.classes.fromNow}>{item.created_at}</Moment>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        )}
                    </div>
                </CardContent>
                }
            </Card>
        )
    }
}

export default withStyles(styles)(Histories);