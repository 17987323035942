import React from 'react';
import ReplayIcon from '@material-ui/icons/Replay';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import AdbIcon from '@material-ui/icons/Adb';


export default function DynamicIcons(props) {
    const project = () => {
        switch(props.icon) {

            case "ReplayIcon":   return <ReplayIcon fontSize="small" style={{display:'inherit'}} />;
            case "PowerSettingsNewIcon":   return <PowerSettingsNewIcon fontSize="small" style={{display:'inherit'}} />;

            default:      return <AdbIcon fontSize="small" style={{display:'inherit'}} />
        }
    }

    return (
        <div>{ project() }</div>
    )
}
